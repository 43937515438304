import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SideTabs.scss";
import { dashBoard } from "../../../utils/constants";

interface SideTabsProps {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const SideTabs: React.FC<SideTabsProps> = ({ tabs, activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const hideCondition = (currentPath: string, tab: string) => {
    return tab === dashBoard.TAB3 && currentPath !== "/dashboard";
  };

  return (
    <div className="side-tabs-component">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`tab-button ${activeTab === tab ? "active-tab-button" : ""} ${
            hideCondition(currentPath, tab) ? "hide-div" : ""
          }`}
          onClick={() => {
            if (tab === dashBoard.TAB3) {
              navigate("/provider-networks");
            } else {
              setActiveTab(tab);
            }
          }}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default SideTabs;
