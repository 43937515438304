import React, { useState, useEffect, useContext } from "react";
import "./Toast.scss";
import globalContext from "../../../context/globalContext";

type ToastProps = {
  duration?: number;
};

const Toast: React.FC<ToastProps> = ({ duration = 10000 }) => {
  const { globalError, setGlobalError }: any = useContext(globalContext);
  const [visible, setVisible] = useState(false);

  const handleToastClick = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (globalError) {
      setVisible(true);
    }
    const timeout = setTimeout(() => {
      setGlobalError(null);
      setVisible(false);
    }, duration);
    return () => clearTimeout(timeout);
  }, [globalError]);

  return visible === true ? (
    <div className="toast" onClick={handleToastClick}>
      {globalError}
    </div>
  ) : null;
};

export default Toast;
