import React, { useEffect, useState, useContext } from "react";
import "./FixedHeaderUserLayout.css";
import RedirectCheck from "../../routes/RedirectCheck";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assests/icons/logo.png";
import menuIcon from "../assests/icons/menu.png";
import close from "../assests/icons/close.png";
import profile from "../assests/icons/profile.png";
import Toast from "../common/Toast/Toast";
import { headerText, storageKeys, signup, common } from "../../utils/constants";
import { homeNavigation } from "../../utils/homeNavigation";
import { logout } from "../../utils/ApiClient";
import globalContext from "../../context/globalContext";
import { clearLocalStorageExceptOID } from "../../utils/clearStorageData";
import Footer from "../common/Footer/Footer";

interface Props {
  children: React.ReactNode;
  hideMyAccButton?: boolean;
}

const FixedHeaderUserLayout: React.FC<Props> = ({
  children,
  hideMyAccButton,
}) => {
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const { globalErrorHandler, menu, setMenu }: any = useContext(globalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const token = localStorage.getItem(storageKeys.TOKEN);
  const hideMyAccountButton = hideMyAccButton ? true : false;

  const handleHomeNavigation = (): void => {
    if (token) {
      const userType = localStorage.getItem(storageKeys.USER_TYPE);
      if (userType === "customer") {
        navigate("/account-info");
      } else if (userType === "prt_user") {
        navigate("/dashboard");
      }
    } else {
      homeNavigation();
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
      navigate("/login");
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (currentPath.startsWith("/chat/") || currentPath === "/") {
      setShowFooter(false);
    }
  }, []);

  return (
    <div className="fixed-header-component fixed-header-layout">
      <RedirectCheck />
      <header className="fixed-header">
        <div className="header">
          <div className="header-content-left">
            <img
              src={logo}
              alt="logo"
              className="fixed-header-logo"
              onClick={handleHomeNavigation}
            />
            <span className="header-title subtitle">{headerText.TITLE}</span>
          </div>
        </div>
        {currentPath === "/" && (
          <img
            src={menu ? close : menuIcon}
            alt="menu-icon"
            className="menu-icon"
            onClick={() => setMenu(!menu)}
          />
        )}
        {hideMyAccountButton || !token ? (
          <>
            {currentPath === "/" && (
              <div onClick={handleNavigate} className="nav-link-div">
                <span className="header-nav-link">{signup.LOGIN_TEXT1}</span>
                <span className="header-nav-link">{signup.LOGIN_TEXT2}</span>
              </div>
            )}
          </>
        ) : (
          <div>
            {currentPath === "/account-info" || currentPath === "/dashboard" ? (
              <div className="button-box">
                <button className="logout-button" onClick={handleLogout}>
                  {"Logout"}
                </button>
              </div>
            ) : (
              <div className="user-div" onClick={handleHomeNavigation}>
                <img src={profile} alt="user" className="avatar" />
                <span>{common.MY_ACCOUNT}</span>
              </div>
            )}
          </div>
        )}
      </header>
      <Toast />
      <div className="content">{children}</div>
      <div className="footer-div">{showFooter && <Footer />}</div>
    </div>
  );
};

export default FixedHeaderUserLayout;
