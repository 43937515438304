import moment from "moment";
import { formatRelative, parseISO } from "date-fns";
import CredentialStorage from "./CredentialStorage";
import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys, supportRequest, common } from "./constants";
import user from "../component/assests/icons/user.png";
import coach from "../component/assests/icons/coach-img.png";

//Function for create channel-name in sendbird-chat
function channelName(): string {
  const currentDate = moment();
  const day = currentDate.date();
  const year = currentDate.year();
  const monthName = currentDate.format("MMMM");
  const firstName = localStorage.getItem(storageKeys.FIRST_NAME) || "";
  const channelName = `${firstName} ${monthName} ${day}, ${year}`;
  return channelName;
}

//Function to calcualte age of User
export function calculateAge(birthdate: string): number {
  const birthDate = moment(birthdate, "DD-MM-YYYY");
  const age = moment().diff(birthDate, "years");
  return age;
}

//Function to get avatar in chat
export const imageSrc = (msgSender: any) => {
  const userId: any = localStorage.getItem(storageKeys.USER_ID);
  if (msgSender?.userId === userId) {
    return user;
  } else {
    return coach;
  }
};

//Function to get date of tickets
export function getFormattedDate(date: Date | string) {
  const momentDate = moment(date);
  const month = momentDate.format("MMMM");
  const day = momentDate.format("DD");
  const year = momentDate.year();
  return `${month}, ${day} ${year}`;
}

export function formateDate(timestamp: any) {
  const date = new Date(timestamp).toISOString();
  const formattedRelativeTime = formatRelative(parseISO(date), new Date(), {
    weekStartsOn: 1,
  });
  return formattedRelativeTime;
}

//Function get user-information for creatinf ticket
export function getTicketData() {
  let eapxValue = CredentialStorage.eapxValues();
  return {
    channel_name: channelName(),
    first_name: localStorage.getItem(storageKeys.FIRST_NAME),
    message_content:
      supportRequest.STATIC_MESSAGE +
      localStorage.getItem(storageKeys.TEXT_COACHNG_QUESTION),
    age: eapxValue.age,
    gender: eapxValue.gender,
    phone_number: eapxValue.phone_number,
    state: eapxValue.state,
    company: eapxValue.company,
  };
}

//Function to get Queryparameters for redirection-url
export function getQueryParams() {
  const firstName: string | null =
    localStorage.getItem(storageKeys.FIRST_NAME) || "";
  const lastName: string | null =
    localStorage.getItem(storageKeys.LAST_NAME) || "";
  const email: string | null = localStorage.getItem(storageKeys.EMAIL) || "";
  const helpForm: string | null =
    localStorage.getItem(storageKeys.HELP_FORM) || "";
  const company: string | null =
    localStorage.getItem(storageKeys.COMPANY) || "";
  const parsedData = helpForm ? JSON.parse(helpForm) : "";
  const phone = parsedData?.phone_number ? parsedData.phone_number : "";
  const queryParams = `?firstName=${encodeURIComponent(
    firstName
  )}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(
    email
  )}&phone=${encodeURIComponent(phone)}&${
    EnvironmentConfig.companyNameField
  }=${encodeURIComponent(company)}`;

  return queryParams;
}

export function transformString(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join("-");
}

export function getSimplifiedUserName(input: string): string {
  const parts: string[] = input.split("_");
  const filteredParts: string[] = parts.filter((part) => isNaN(Number(part)));
  const capitalizedParts: string[] = filteredParts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });
  return capitalizedParts.join(" ");
}

export const getClassNameForService = (
  serviceType: string,
  activeSection: string,
  sectionName: string,
  menu: boolean
) => {
  const activeClass = menu ? "active-menu" : "active";
  const commonClass = "menu-items";

  switch (sectionName) {
    case "service1":
      return serviceType?.includes(common.TEXT_COACHING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service2":
      return serviceType?.includes(common.SELF_SCHEDULING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service3":
      return EnvironmentConfig.showWorkLifeRequest &&
        (serviceType?.includes(common.TEXT_COACHING) ||
          serviceType?.includes(common.SELF_SCHEDULING))
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service4":
      return serviceType?.includes(common.TEXT_COACHING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    default:
      return "hide-div";
  }
};

export const getClassNameForServiceVisibility = (
  serviceType: any,
  condition: any,
  isSpecialCondition = false
) => {
  if (isSpecialCondition) {
    return EnvironmentConfig.showWorkLifeRequest &&
      (serviceType?.includes(condition[0]) ||
        serviceType?.includes(condition[1]))
      ? "show-div"
      : "hide-div";
  }
  return serviceType?.includes(condition) ? "show-div" : "hide-div";
};