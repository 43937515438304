import axios from "axios";
import { AxiosRequestConfig } from "axios";

import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys } from "./constants";

export const headers = {
  "Content-Type": "application/json",
};

export const authHeaders = () => {
  const headers: AxiosRequestConfig["headers"] = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem(storageKeys.TOKEN) || null,
  };
  return headers;
};

//Api for Registration
export const register = (data: any) => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/signup/`, data, {
    headers: headers,
  });
};

//Api for Login
export const logIn = (data: any) => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/login/`, data, {
    headers: headers,
  });
};

//Api for verify user through magic-link
export const userVerification = (user_id: any, magic_token: any) => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/verify/${user_id}/${magic_token}`
  );
};

//Api for get EAPX organization detail using oId
export const getOrganizationDetail = (oId: any) => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/eapx/organization?oid=${oId}`,
    {
      headers: headers,
    }
  );
};

//Api for Logout
export const logout = async () => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/logout/`,
    {},
    {
      headers: authHeaders(),
    }
  );
};

//Api for Updated tickets
export const getTicketUpdates = () => {
  return axios.patch(
    `${EnvironmentConfig.ccaApiRoot}/ticket/update`,
    {},
    {
      headers: authHeaders(),
    }
  );
};

//Api for Ticket-list
export const ticketList = () => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/tickets/list`, {
    headers: authHeaders(),
  });
};

//Api for send data to eapx
export const sendDataToEAPX = (data: any) => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/eapx`, {
    params: data,
    headers: authHeaders(),
  });
};

//Api for create-ticket
export const createTicket = (data: any) => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/create-ticket/`, data, {
    headers: authHeaders(),
  });
};

//Api for get organization detail using user
export const getUserOrganizationDetail = () => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/organization/detail`, {
    headers: authHeaders(),
  });
};

// API to get ticket info
export const getTicketInfo = (ticketId: any) => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/ticket/get?ticket_id=${ticketId}`,
    {
      headers: authHeaders(),
    }
  );
};

//Api to get control which services are available
export const getServiceType = (oId: any) => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/eapx/organization/services?oid=${oId}`
  );
};

//Api to test email delivery
export const emailCheck = (email: string) => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/email-check`,
    {
      user_email: email,
      support_email: EnvironmentConfig.supportEmail,
    },
    {
      headers: headers,
    }
  );
};

//Api to test service is working or not via email
export const serviceCheck = () => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/sendbird-check`, {
    headers: headers,
  });
};

//Api to send request-data for work-life request flow
export const sendWorklifeRequestData = (requestData: any) => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/worklife-request/data/`,
    requestData,
    {
      headers: headers,
    }
  );
};

//Api to get customer-list to send-refferal
export const getCustomers = () => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/customers/`, {
    headers: authHeaders(),
  });
};

//Api to send-refferal email
export const sendRefferalEmail = (
  data: any,
  value: any,
  providerName: string
) => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/send-referral-email/`,
    {
      user: value.id,
      provider_id: data.id,
      provider_type: providerName,
      referral_email_body : data,
    },
    {
      headers: authHeaders(),
    }
  );
};

//Api to verify refferal request
export const refferalRequestVerification = (user_id: any, magic_token: any) => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/referral-request-verify/${user_id}/${magic_token}`
  );
};

//Api to get all refferals
export const getAllRefferals = () => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/referral-request/`, {
    headers: authHeaders(),
  });
};

//Api to get all refferals
export const getMyRefferals = (id: number) => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/referral-request/?referred_by=${id}`,
    {
      headers: authHeaders(),
    }
  );
};

//Api to get customer refferals
export const getCustomerRefferals = () => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/customer-referral-request/`,
    {
      headers: authHeaders(),
    }
  );
};
