import "./CCADatePicker.scss";
import {FC} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface Props {
    value: Date | null;
    placeholder: string;
    onChange: (date: Date | null) => void;
    onClick: () => void;
    maxDate?: Date;
}

export const CCADatePicker: FC<Props> = (props) => {

    return(
        <div className="cca-date-picker-component">
            <DatePicker 
                onChange={props.onChange} 
                value={props.value}
                onOpen={props.onClick}
                format="MM/dd/yyyy"
                label={props.placeholder}
                closeOnSelect={true}
            />
        </div>
    );
}
