type QuestionType = "text" | "radio" | "number";

export interface Question {
  id: string;
  type: QuestionType;
  text: string;
  options?: string[]; 
}

export interface Request {
  id: string;
  type: "request";
  name: string;
  questions: Question[];
}

export interface Category {
  id: string;
  type: "Category";
  name: string;
  items: (Request | Category)[];
}

type WorkLifeData = Category [];

export const workLifeData : WorkLifeData = [
      {
        id: "cat1",
        type: "Category",
        name: "Child care",
        items: [
          {
            id: "req1",
            type: "request",
            name: "Childcare Options",
            questions: [
              { id: "q1", type: "text", text: "Description of Needs." },
              { id: "q2", type: "text", text: "Child’s name." },
              { id: "q3", type: "text", text: "Child’s date of birth." },
              {
                id: "q4",
                type: "radio",
                text: "Allergies or special needs?",
                options: ["Yes", "No"],
              },
              {
                id: "q5",
                type: "radio",
                text: "Type of childcare environment.",
                options: ["Center", "Family Daycare", "In-home Nanny"],
              },
              {
                id: "q6",
                type: "radio",
                text: "Transportation needed?",
                options: ["Yes", "No"],
              },
              {
                id: "q7",
                type: "radio",
                text: "Okay with pets?",
                options: ["Yes", "No"],
              },
              {
                id: "q8",
                type: "radio",
                text: "Is Child walking?",
                options: ["Yes", "No"],
              },
              {
                id: "q9",
                type: "radio",
                text: "Is Child Potty trained?",
                options: ["Yes", "No"],
              },
              {
                id: "q10",
                type: "text",
                text: "If you have a budget, please indicate amount.",
              },
              {
                id: "q11",
                type: "text",
                text: "If unable to meet budget, would you like average cost in area?",
              },
              {
                id: "q12",
                type: "text",
                text: "If limited budget, would you like info on state’s child care subsidy programs",
              },
              {
                id: "q13",
                type: "text",
                text: "On which days of week is care needed?",
              },
              {
                id: "q14",
                type: "text",
                text: "How many hours per day care is needed?",
              },
              {
                id: "q15",
                type: "radio",
                text: "Will schedule vary?",
                options: ["Yes", "No"],
              },
              { id: "q16", type: "text", text: "Describe schedule variations." },
              { id: "q17", type: "text", text: "Earliest start time for care." },
              { id: "q18", type: "text", text: "Latest end time for care." },
              {
                id: "q19",
                type: "text",
                text: "Any providers you would like us to contact.",
              },
              { id: "q20", type: "text", text: "Primary search area zipcode." },
              {
                id: "q21",
                type: "radio",
                text: "Located near public transportation.",
                options: ["Yes", "No"],
              },
              { id: "q22", type: "text", text: "How far are you willing to drive?" },
              { id: "q23", type: "number", text: "Search radius (in Miles)." },
              { id: "q24", type: "text", text: "Additional, relevant information." },
            ],
          },
          {
            id: "req2",
            type: "request",
            name: "Education Options",
            questions: [
              { id: "q1", type: "text", text: "Description of Needs." },
              { id: "q2", type: "text", text: "Child’s name." },
              { id: "q3", type: "text", text: "Child’s date of birth." },
              { id: "q4", type: "text", text: "Type of education being sought." },
              { id: "q5", type: "text", text: "Type of school to search for." },
              { id: "q6", type: "text", text: "Specify any preferences." },
              { id: "q7", type: "text", text: "Planned enrollment date." },
              { id: "q8", type: "text", text: "Grade of entry." },
              { id: "q9", type: "text", text: "Any schools to contact." },
              { id: "q10", type: "text", text: "Primary search area." },
              {
                id: "q11",
                type: "radio",
                text: "May we expand outside of search area.",
                options: ["Yes", "No"],
              },
              { id: "q12", type: "text", text: "Additional information." },
            ],
          },
        ]
      },
      {
        id: "cat2",
        type: "Category",
        name: "Adult care",
        items: [
              {
                id: "req3",
                type: "request",
                name: "Elder Care",
                questions: [
                  {
                    id: "q1",
                    type: "text",
                    text: "How many require service. What is their age and where are they located"
                  },
                  { id: "q2", type: "text", text: "Any care needs and concerns?" },
                  {
                    id: "q3",
                    type: "radio",
                    text: "With what tasks of daily living does the person require assistance?",
                    options: [
                      "Bathing",
                      "Dressing",
                      "Ambulation/mobility",
                      "Meal prep",
                      "Medication reminders",
                      "Light house keeping",
                      "Transportation",
                      "Other"
                    ]
                  },
                  {
                    id: "q4",
                    type: "text",
                    text: "Any physical or cognitive impairments. If yes, please explain."
                  },
                  { id: "q5", type: "text", text: "Days and hours needed." },
                  { id: "q6", type: "text", text: "When is care needed to start?" },
                  { id: "q7", type: "text", text: "What is the person's primary insurance?" },
                  {
                    id: "q8",
                    type: "text",
                    text: "In-home services needed (i.e. Home care, emergency response pendant, meals on wheels)"
                  },
                  {
                    id: "q9",
                    type: "text",
                    text: "Housing/Residential services needed (i.e. Independent Senior Housing, Nursing Home, Assisted Living)"
                  },
                  {
                    id: "q10",
                    type: "text",
                    text: "Caregiver Services Needed (i.e., support groups, respite care)"
                  },
                  {
                    id: "q11",
                    type: "text",
                    text: "Community Services Needed (i.e., Adult day care, Senior Centers, transportation)"
                  },
                  {
                    id: "q12",
                    type: "text",
                    text: "Legal and Financial Planning (Elder law attorney, long-term care planning)"
                  },
                  {
                    id: "q13",
                    type: "text",
                    text: "Insurance and Benefits needed (Medicaid, Medicare, Veterans Benefits"
                  },
                  {
                    id: "q14",
                    type: "text",
                    text: "Health and Medical Services Needed (Physician referral, Nursing, Physical/Occupational therapy)"
                  },
                  { id: "q15", type: "text", text: "Are other services needed that are not listed above?" },
                  { id: "q16", type: "number", text: "Primary search area zipcode" },
                  { id: "q17", type: "text", text: "Additional, relevant information." }
                ]
              },
              {
                id: "req4",
                type: "request",
                name: "Support Groups",
                questions: [
                  { id: "q1", type: "text", text: "What is the support group for." },
                  { id: "q2", type: "text", text: "What is the issue you need support dealing with." },
                  { id: "q3", type: "text", text: "Would online groups be helpful." },
                  {
                    id: "q4",
                    type: "radio",
                    text: "Do you have a preference for in-person groups, online or both.",
                    options: ["Yes", "No"]
                  },
                  {
                    id: "q5",
                    type: "radio",
                    text: "Are you open to religiously affiliated groups?",
                    options: ["Yes", "No"]
                  },
                  {
                    id: "q6",
                    type: "radio",
                    text: "Is there a specific religious affiliation that you only want or are you open to a variety?",
                    options: ["Yes", "No"]
                  },
                  {
                    id: "q7",
                    type: "radio",
                    text: "Do you want education materials on the topic requested?",
                    options: ["Yes", "No"]
                  },
                  { id: "q8", type: "text", text: "What resources are you seeking?" },
                  { id: "q9", type: "text", text: "What is your primary search area?" },
                  {
                    id: "q10",
                    type: "text",
                    text: "Please include any additional, relevant information."
                  }
                ]
              } 
          ]
      },
      {
        id: "cat3",
        type: "Category",
        name: "Daily Living",
        items :[
          {
            id: "req5",
            type: "request",
            name: "Career",
            questions:  [
              { id: "q1", type: "text", text: "Type of assistance. Detailed list." },
              { id: "q2", type: "text", text: "Why are you looking?" },
              { id: "q3", type: "radio", text: "Type of services looking for?",options: [
                  "Job Placement",
                  "Resume Writing",
                  " Interview Coaching",
                  "Career Counseling",
                ] },
              { id: "q4", type: "text", text: "Would online resources be helpful?"},
              { id: "q5", type: "radio", text: "Are you open to in-person or telephonic options?", options :["Yes", "No"]},
              { id: "q6", type: "text", text: "Do you have a desired appointment time or time of day?"},
              { id: "q7", type: "text", text: "Free or low-cost options."},
              { id: "q8", type: "text", text: "Primary search area."},
              { id: "q9", type: "text", text: "Additional, relevant information."},
            ]
          },
          {
            id: "req6",
            type: "request",
            name: "Disaster Resources",
            questions:  [
              { id: "q1", type: "text", text: "What is the disaster?" },
              { id: "q2", type: "text", text: "What resources are you seeking?" },
              { id: "q3", type: "text", text: "What is your primary search area?"},
              { id: "q4", type: "text", text: "May we expand outside of your search area?"},
              { id: "q5", type: "text", text: "Additional, relevant information."},
            ]
          },
          {
            id: "req7",
            type :"request",
            name: "Household Services",
            questions:  [
                { id: "q1", type: "radio", text: "What type of service or repair work is needed?", 
                  options :["Contractor", "Handyman", "Plumber", "Electrician", "Roofer", "Lawn/Yard Care", "Housecleaner", "Other"] },
                { id: "q2", type: "radio", text: "Is there a timeframe the service is needed to be completed by?" , options :["Yes", "No"]},
                { id: "q3", type: "text", text: "Primary search area." },
                { id: "q4", type: "text", text: "Any additional, relevant information."},
              ]
          },
          {
            id: "sub1",
            type: "Category",
            name :"Pets",
            items: [
                {
                    id: "req8",
                    type: "request",
                    name: "Boarding Facility",
                    questions:  [
                      { id: "q1", type: "text", text: "When is care needed?" },
                      { id: "q2", type: "radio", text: "What type of animal?" , options :["Dog", "Cat", "Other"]},
                      { id: "q3", type: "text", text: "Breed, Age, Weight, Sex" },
                      { id: "q4", type: "radio", text: "Any medical, dietary, or behavioral concerns.", options :["Yes", "No"]},
                      { id: "q5", type: "radio", text: "Current on vaccinations.", options :["Yes", "No"]},
                      { id: "q6", type: "radio", text: "Has pet ever been to a kennel?", options :["Yes", "No"]},
                      { id: "q7", type: "text", text: "Primary search area." },
                      { id: "q8", type: "text", text: "Additional, relevant information." },
                    ]
                },
                {
                  id: "req9",
                  type: "request",
                  name: "Doggie Daycare",
                  questions:  [
                    { id: "q1", type: "text", text: "Time to drop off pet." },
                    { id: "q2", type: "text", text: "Time to pick up pet." },
                    { id: "q3", type: "text", text: "Breed, Age, Weight, Sex" },
                    { id: "q4", type: "radio", text: "Any medical, dietary, or behavioral concerns.", options :["Yes", "No"]},
                    { id: "q5", type: "radio", text: "Current on vaccinations.", options :["Yes", "No"]},
                    { id: "q6", type: "radio", text: "Has pet ever been to a daycare?", options :["Yes", "No"]},
                    { id: "q7", type: "text", text: "Primary search area." },
                    { id: "q8", type: "text", text: "Additional, relevant information." },
                  ]
                },
                {
                id: "req10",
                type: "request",
                name: "Groomer",
                questions:  [
                  { id: "q1", type: "text", text: "Specific services needed." },
                  { id: "q2", type: "radio", text: "What type of animal?" , options :["Dog", "Cat", "Other"]},
                  { id: "q3", type: "text", text: "Breed, Age, Weight, Sex" },
                  { id: "q4", type: "radio", text: "Any medical, dietary, or behavioral concerns.",  options :["Yes", "No"]},
                  { id: "q5", type: "radio", text: "Current on vaccinations.", options :["Yes", "No"]},
                  { id: "q6", type: "radio", text: "Has pet ever been to a daycare?", options :["Yes", "No"]},
                  { id: "q7", type: "text", text: "Primary search area." },
                  { id: "q8", type: "text", text: "Additional, relevant information." },
                ]
                },
                {
                  id: "req11",
                  type: "request",
                  name: "Obedience Training",
                  questions:  [
                    { id: "q1", type: "text", text: "What skills would you like your pet to learn?" },
                    { id: "q2", type: "text", text: "Would you like group or individual training?" },
                    { id: "q3", type: "radio", text: "What type of animal do you have?", options :["Dog", "Cat", "Other"]},
                    { id: "q4", type: "text", text: "Breed, Age, Weight, Sex" },
                    { id: "q5", type: "text", text: "Primary search area." },
                  ]
                },
                {
                  id: "req12",
                  type: "request",
                  name: "Insurance",
                  questions:  [
                    { id: "q1", type: "text", text: "What skills would you like your pet to learn?" },
                    { id: "q2", type: "radio", text: "What type of animal?" , options :["Dog", "Cat", "Other"]},
                    { id: "q3", type: "text", text: "Breed, Age, Weight, Sex" },
                    { id: "q4", type: "text", text: "Primary search area." },
                    { id: "q5", type: "radio", text: "Any medical, dietary, or behavioral concerns.",  options :["Yes", "No"]},
                  ]
                },
                {
                  id: "req13",
                  type: "request",
                  name: "Sitter",
                  questions:  [
                    { id: "q1", type: "text", text: "How many times per day would you like the sitter to visit?" },
                    { id: "q2", type: "text", text: "When is care needed?" },
                    { id: "q3", type: "radio", text: "Has your pet ever had a sitter?", options :["Yes", "No"] },
                    { id: "q4", type: "text", text: "Do you want pet sitter to provide any other services. Please describe." },
                    { id: "q5", type: "radio", text: "What type of animal?" , options :["Dog", "Cat", "Other"]},
                    { id: "q6", type: "text", text: "Breed, Age, Weight, Sex" },
                    { id: "q7", type: "text", text: "Primary search area." },
                    { id: "q8", type: "radio", text: "Any medical, dietary, or behavioral concerns.", options :["Yes", "No"]},
                  ]
                },
                {
                  id: "req14",
                  type: "request",
                  name: "Veterinarian",
                  questions:  [
                    { id: "q1", type: "text", text: "What specific services are needed?" },
                    { id: "q2", type: "radio", text: "What type of animal do you have?", options :["Dog", "Cat", "Other"]},
                    { id: "q3", type: "text", text: "Breed, Age, Weight, Sex" },
                    { id: "q5", type: "text", text: "Primary search area." },
                    { id: "q6", type: "radio", text: "Any medical, dietary, or behavioral concerns.", options :["Yes", "No"]},
                  ]
                }
            ]
          },
          {
            id: "sub2",
            type: "Category",
            name :"Social Services",
            items : [
              {
                id: "req15",
                type: "request",
                name: "Utility",
                questions:  [
                  { id: "q1", type: "text", text: "Have you received a shut-off notice.  If YES, when?" },
                  { id: "q2", type: "text", text: "How much do you owe in current and past-due utility bills?" },
                  { id: "q3", type: "text", text: "Who is the utility provider?" },
                  { id: "q4", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "Are there any minors or elders in the home?" },
                  { id: "q6", type: "text", text: "What resources have you already located?" },
                  { id: "q7", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q8", type: "text", text: "What county do you live in?" },
                  { id: "q9", type: "text", text: "What is your primary search area?" },
                  { id: "q10", type: "text", text: "Include any additional, relevant information." },
                ]
              },
              {
                id: "req16",
                type: "request",
                name: "Water/Sewer",
                questions:  [
                  { id: "q1", type: "text", text: "Have you received a shut-off notice. If YES, when?" },
                  { id: "q2", type: "text", text: "How much do you owe in current and past-due bills?" },
                  { id: "q3", type: "text", text: "Who is the utility provider?" },
                  { id: "q4", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "Are there any minors or elders in the home?" },
                  { id: "q6", type: "text", text: "What resources have you already located?" },
                  { id: "q7", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q8", type: "text", text: "What county do you live in?" },
                  { id: "q9", type: "text", text: "What is your primary search area?" },
                  { id: "q10", type: "text", text: "Include any additional, relevant information." },
                ]
              },
              {
                id: "req17",
                type: "request",
                name: "Electric",
                questions:  [
                  { id: "q1", type: "text", text: "Have you received a shut-off notice. If YES, when?" },
                  { id: "q2", type: "text", text: "How much do you owe in current and past-due bills?" },
                  { id: "q3", type: "text", text: "Who is the utility provider?" },
                  { id: "q4", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "What resources have you already located?" },
                  { id: "q6", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q7", type: "text", text: "What county do you live in?" },
                  { id: "q8", type: "text", text: "What is your primary search area?" },
                  { id: "q9", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req18",
                type: "request",
                name: " Natural Gas",
                questions:  [
                  { id: "q1", type: "text", text: "Have you received a shut-off notice. If YES, when?" },
                  { id: "q2", type: "text", text: "How much do you owe in current and past-due bills?" },
                  { id: "q3", type: "text", text: "Who is the utility provider?" },
                  { id: "q4", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "What resources have you already located?" },
                  { id: "q6", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q7", type: "text", text: "What county do you live in?" },
                  { id: "q8", type: "text", text: "What is your primary search area?" },
                  { id: "q9", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req19",
                type: "request",
                name: "First Month's Rent/Security Deposit",
                questions:  [
                  { id: "q1", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q2", type: "text", text: "What resources have you already located?" },
                  { id: "q3", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q4", type: "text", text: "What county do you currently reside in?" },
                  { id: "q5", type: "text", text: "What is your primary search area?" },
                  { id: "q6", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req20",
                type: "request",
                name: "Food",
                questions:  [
                  { id: "q1", type: "text", text: "Would you like information on food pantries or food stamps?" },
                  { id: "q2", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q3", type: "text", text: "What resources have you already located?" },
                  { id: "q4", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "What county do you currently reside in?" },
                  { id: "q6", type: "text", text: "What is your primary search area?" },
                  { id: "q7", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req21",
                type: "request",
                name: "Clothing",
                questions:  [
                  { id: "q1", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q2", type: "text", text: "What resources have you already located?" },
                  { id: "q3", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q4", type: "text", text: "What county do you currently reside in?" },
                  { id: "q5", type: "text", text: "What is your primary search area?" },
                  { id: "q6", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req22",
                type: "request",
                name: "Prescription",
                questions:  [
                  { id: "q1", type: "text", text: "Please provide the exact spelling of the medications." },
                  { id: "q2", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q3", type: "text", text: "What resources have you already located?" },
                  { id: "q4", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "What county do you currently reside in?" },
                  { id: "q6", type: "text", text: "What is your primary search area?" },
                  { id: "q7", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req23",
                type: "request",
                name: "Medical Bills",
                questions:  [
                  { id: "q1", type: "text", text: "What are the medical bills for?" },
                  { id: "q2", type: "text", text: "What is the name of the medical provider?" },
                  { id: "q3", type: "text", text: "Name of disease or diagnosis." },
                  { id: "q4", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "What resources have you already located?" },
                  { id: "q6", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q7", type: "text", text: "What county do you currently reside in?" },
                  { id: "q8", type: "text", text: "What is your primary search area?" },
                  { id: "q9", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req24",
                type: "request",
                name: "Other",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain." },
                  { id: "q2", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"] },
                  { id: "q3", type: "text", text: "What resources have you already located?" },
                  { id: "q4", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"] },
                  { id: "q5", type: "text", text: "What county do you currently reside in?" },
                  { id: "q6", type: "text", text: "What is your primary search area?" },
                  { id: "q7", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req25",
                type: "request",
                name: "Mortgage",
                questions:  [
                  { id: "q1", type: "text", text: "Who is your mortgage lender?" },
                  { id: "q2", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"]},
                  { id: "q3", type: "radio", text: "Are there any minors or elders in the home?", options: ["Yes", "No"] },
                  { id: "q4", type: "text", text: "What resources have you already located?" },
                  { id: "q5", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"]},
                  { id: "q6", type: "text", text: "What county do you currently reside in?" },
                  { id: "q7", type: "number", text: "What is your primary search area?" },
                  { id: "q8", type: "number", text: "Any additional, relevant information." },
                ]
              },
              {
              id: "req26",
              type: "request",
              name: "Rent",
              questions:  [
                { id: "q1", type: "text", text: "Have you received an eviction notice. If YES, when?" },
                { id: "q2", type: "text", text: "How much do you owe in current and past-due rent?" },
                { id: "q3", type: "radio", text: "Is this a domestic violence situation?", options: ["Yes", "No"]},
                { id: "q4", type: "radio", text: "Are there any minors or elders in the home?", options: ["Yes", "No"] },
                { id: "q5", type: "text", text: "What resources have you already located?" },
                { id: "q6", type: "radio", text: "Is anyone in your household or family a veteran?", options: ["Yes", "No"]},
                { id: "q7", type: "text", text: "What county do you currently reside in?" },
                { id: "q8", type: "number", text: "What is your primary search area?" },
                { id: "q9", type: "number", text: "Any additional, relevant information." },
              ]
              }
            ]
          },
          {
            id: "sub3",
            type: "Category",
            name :"Travel",
            items : [
              {
                id: "req27",
                type: "request",
                name: "Travel Agents",
                questions:  [
                  { id: "q1", type: "text", text: "What is your budget?" },
                  { id: "q2", type: "number", text: "How many hotel rooms do you require?" },
                  { id: "q3", type: "number", text: "How many beds do you require?" },
                  { id: "q4", type: "text", text: "Please list any specific amenities that you will require." },
                  { id: "q5", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req28",
                type: "request",
                name: "Hotels",
                questions:  [
                  { id: "q1", type: "text", text: "What is your budget?" },
                  { id: "q2", type: "number", text: "How many hotel rooms do you require?" },
                  { id: "q3", type: "number", text: "How many beds do you require?" },
                  { id: "q4", type: "text", text: "Please list any specific amenities that you will require." },
                  { id: "q5", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req29",
                type: "request",
                name: "Rental Cars",
                questions:  [
                  { id: "q1", type: "text", text: "What is your budget?" },
                  { id: "q2", type: "number", text: "What car size do you prefer?" },
                  { id: "q3", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req30",
                type: "request",
                name: "Flights",
                questions:  [
                  { id: "q1", type: "text", text: "What is your budget?" },
                  { id: "q2", type: "text", text: "What airport would you like to depart out of?" },
                  { id: "q3", type: "text", text: "What type of flight? One-way or roundtrip?" },
                  { id: "q4", type: "text", text: "What is your destination?" },
                  { id: "q5", type: "text", text: "What is your preferred arrival time?" },
                  { id: "q6", type: "text", text: "What time of day would you prefer to fly?" },
                  { id: "q7", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
              id: "req31",
              type: "request",
              name: "Other",
              questions:  [
                { id: "q1", type: "text", text: "Please explain." },
                { id: "q2", type: "radio", text: "Do you have a specific location or your travel?", options: ["Yes", "No"] },
                { id: "q3", type: "text", text: "What is the most important part of the trip for you?" },
                { id: "q4", type: "text", text: "What are the dates of travel?" },
                { id: "q5", type: "text", text: "How many people will be traveling?" },
                { id: "q6", type: "radio", text: "Are there any elders or children that will be traveling?", options: ["Yes", "No"]},
                { id: "q7", type: "text", text: "What is your budget?" },
                { id: "q8", type: "number", text: "How many hotel rooms do you require?" },
                { id: "q9", type: "number", text: "How many beds do you require?" },
                { id: "q10", type: "number", text: "What resources are you seeking?" },
                { id: "q11", type: "number", text: "Please list any specific amenities that you will require." },
                { id: "q12", type: "number", text: "Please include any additional, relevant information." },
              ]
              }
            ]
          },
          {
            id: "sub4-a",
            type: "Category",
            name: "Relocation",
            items : 
            [
              {
                id: "req58",
                type: "request",
                name: "Relocation - General",
                questions:  [
                  { id: "q1", type: "text", text: "How many boxes or large furniture items do you need to move?" },
                  { id: "q2", type: "text", text: "What size of a truck do you expect you will need?" },
                  { id: "q3", type: "text", text: "Are you in need of any other services in your new location?", options :["Yes", "No"]},
                  { id: "q4", type: "text", text: "What services do you require?" },
                  { id: "q5", type: "text", text: "Moving Company - What is the size of the home you are moving from?" },
                  { id: "q6", type: "text", text: "What is the address of the location you wish to move to?" },
                  { id: "q7", type: "text", text: "Do you have any items that need special care during a move?" },
                  { id: "q8", type: "text", text: "What is the date of your move?" },
                  { id: "q9", type: "text", text: "What is your primary search area." },
                  { id: "q10", type: "text", text: "Any additional, relevant information." },
                ]
              },
              {
                id: "sub4-b",
                type: "Category",
                name :"Relocation",
                items : [
                  {
                    id: "req59",
                    type: "request",
                    name: "Truck Rental",
                    questions:  [
                      { id: "q1", type: "radio", text: "Will you be returning the truck to the pick-up location?", options :["Yes", "No"] },
                      { id: "q2", type: "number", text: "How many bedrooms are you wanting to move?" },
                      { id: "q3", type: "text", text: "What is the date of your move?" },
                      { id: "q4", type: "text", text: "What is your primary search area?" },
                      { id: "q5", type: "text", text: "Include any additional, relevant information." },
                    ]
                  },
                  {
                    id: "req60",
                    type: "request",
                    name: "Storage Facility",
                    questions:  [
                      { id: "q1", type: "text", text: "How many rooms are you storing or do you know what size unit?" },
                      { id: "q2", type: "radio", text: "Does the unit need to be temperature-controlled?", options :["Yes", "No"] },
                      { id: "q3", type: "text", text: "What is the date of your move?" },
                      { id: "q4", type: "text", text: "What is your primary search area?" },
                      { id: "q5", type: "text", text: "Include any additional, relevant information." },
                    ]
                  },
                  {
                    id: "req61",
                    type: "request",
                    name: "Car Transportation",
                    questions:  [
                      { id: "q1", type: "radio", text: "Do you have shipping preference(enclosed or 4-wheeler)?", options :["Yes", "No"]},
                      { id: "q2", type: "text", text: "What kind of car?" },
                      { id: "q3", type: "text", text: "Where does the car need to be picked up from?" },
                      { id: "q4", type: "text", text: "Where does the car need to be dropped off?" },
                      { id: "q5", type: "text", text: "Are there desired dates for the pick up or drop off?" },
                      { id: "q6", type: "text", text: "What is your primary search area?" },
                      { id: "q7", type: "text", text: "Include any additional, relevant information." },
                    ]
                  },
                  {
                    id: "req62",
                    type: "request",
                    name: "POD",
                    questions:  [
                      { id: "q1", type: "number", text: "How may rooms are you storing or do you know what size unit.", options :["Yes", "No"] },
                      { id: "q2", type: "radio", text: "Does the unit need to be temperature-controlled?", options :["Yes", "No"] },
                      { id: "q3", type: "text", text: "How long do you need to pack the POD?" },
                      { id: "q4", type: "text", text: "How long will you need the POD stored?" },
                      { id: "q5", type: "text", text: "What is the date of your move?" },
                      { id: "q6", type: "text", text: "What is your primary search area?" },
                      { id: "q7", type: "text", text: "Include any additional, relevant information." },
                    ]
                  },
                  {
                    id: "req63",
                    type: "request",
                    name: "Neighborhood Information",
                    questions:  [
                      { id: "q1", type: "text", text: "Please specify." },
                      { id: "q2", type: "text", text: "What is the date of your move?" },
                      { id: "q3", type: "text", text: "What is your primary search area?" },
                      { id: "q4", type: "text", text: "Include any additional, relevant information." },
                    ]
                  },
                  {
                    id: "req64",
                    type: "request",
                    name: "Other",
                    questions:  [
                      { id: "q1", type: "text", text: "Please specify." },
                      { id: "q2", type: "text", text: "What is the date of your move?" },
                      { id: "q3", type: "text", text: "What is your primary search area?" },
                      { id: "q4", type: "text", text: "Include any additional, relevant information." },
                    ]
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        id: "cat4",
        type: "Category",
        name: "Financial",
        items: [
              {
                id: "req32",
                type: "request",
                name: "Taxes",
                questions:  [
                  { id: "q1", type: "radio", text: "Are you looking for free or low cost?", options : ["Yes", "No"] },
                  { id: "q2", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q3", type: "text", text: "When do you wish to use the service?" },
                  { id: "q4", type: "text", text: "What is your primary search area?" },
                  { id: "q5", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req33",
                type: "request",
                name: "Investments",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q2", type: "text", text: "When do you wish to use the service?" },
                  { id: "q3", type: "text", text: "What is your primary search area?" },
                  { id: "q4", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req34",
                type: "request",
                name: "Retirement",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q2", type: "text", text: "When do you wish to use the service?" },
                  { id: "q3", type: "text", text: "What is your primary search area?" },
                  { id: "q4", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req35",
                type: "request",
                name: "Loans",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q2", type: "text", text: "When do you wish to use the service?" },
                  { id: "q3", type: "text", text: "What is your primary search area?" },
                  { id: "q4", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req36",
                type: "request",
                name: "Credit",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q2", type: "text", text: "When do you wish to use the service?" },
                  { id: "q3", type: "text", text: "What is your primary search area?" },
                  { id: "q4", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req37",
                type: "request",
                name: "Debt",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q2", type: "text", text: "When do you wish to use the service?" },
                  { id: "q3", type: "text", text: "What is your primary search area?" },
                  { id: "q4", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req38",
                type: "request",
                name: "Financial - Other",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain what you are looking for assistance with." },
                  { id: "q2", type: "text", text: "When do you wish to use the service?" },
                  { id: "q3", type: "text", text: "What is your primary search area?" },
                  { id: "q4", type: "text", text: "Please include any additional, relevant information." },
                ]
              }
        ]
      },
      {
        id: "cat5",
        type: "Category",
        name: "Health and wellness",
        items: [
              {
                id: "req39",
                type: "request",
                name: "Health and Wellness",
                questions:  [
                  { id: "q1", type: "text", text: "What are your health concerns." },
                  { id: "q2", type: "radio", text: "What is the individual’s health insurance?", options :["General Practitioner",
                    "Counseling / Psychologist",
                    "Psychiatrist",
                    "Dentist",
                    "Nutritionist / Dietitian",
                    "Substance Abuse",
                    "Specialist",
                    "Other"
                  ] },
                  { id: "q3", type: "text", text: "What is type of provider are you seeking?" },
                  { id: "q4", type: "text", text: "When is your desired appointment date?" },
                  { id: "q5", type: "text", text: "What is the preferred timeframe?" },
                ]
              }
        ]
      },
      {
        id: "cat6",
        type: "Category",
        name: "Housing",
        items :[
              {
                id: "req40",
                type: "request",
                name: "Low Cost Housing",
                questions:  [
                  { id: "q1", type: "radio", text: "What type of low cost housing resources are you searching for?", options : [
                    "Subsidized", "Roommate Locators", "Other"
                  ] },
                  { id: "q2", type: "radio", text: "Who is going to be living with you?", options : [
                    "Childer", "Parents", "Relative", "Spouse", "Other"
                  ] },
                  { id: "q3", type: "text", text: "How many bedrooms and bathrooms do you require?" },
                  { id: "q4", type: "text", text: "Do you believe you have any disqualifying factors in finding new housing?" },
                  { id: "q5", type: "radio", text: "Have you applied for Section vouchers?", options : ["Yes", "No"] },
                  { id: "q6", type: "text", text: "Would you like us to find housing that accepts these vouchers?" },
                  { id: "q7", type: "text", text: "What is your primary search area?" },
                  { id: "q8", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req41",
                type: "request",
                name: "Market-Rate Housing",
                questions :[
                  { id: "q1", type: "radio", text: "What type of Housing are you seeking?", options :["Apartment", "Rental Home/Townhome", "Other"] },
                  { id: "q2", type: "text", text: "How many bedrooms and bathrooms do you require?" },
                  { id: "q3", type: "text", text: "What is your MAX budget?" },
                  { id: "q4", type: "text", text: "Who is going to be living with you?" },
                  { id: "q5", type: "text", text: "Do you require any specific amenities?" },
                  { id: "q6", type: "radio", text: "Are any children going to be living with you?", options :["Yes", "No"] },
                  { id: "q7", type: "text", text: "Do you have pets. If YES, goes to What is the breed and weight of your pet." },
                  { id: "q8", type: "text", text: "What is the preferred move-in date?" },
                  { id: "q9", type: "radio", text: "Do you require a lease agreement shorter than 12 months?", options :["Yes", "No"] },
                  { id: "q10", type: "radio", text: "Do you have any concerns with credit or past evictions?", options :["Yes", "No"] },
                  { id: "q11", type: "radio", text: "What features of the housing resources would you like us to prioritize in our search?", options :["Budget", "Location", "Amenities", "Pet Policy", "Other"] },
                  { id: "q12", type: "text", text: "What is your primary search area?" },
                  { id: "q13", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req42",
                type: "request",
                name: "Shelters/Transitional Housing",
                questions :[
                  { id : "q1", type :"radio", text :"Do you have a place to stay tonight?", options :["Yes", "No"]},
                  { id : "q2", type :"text", text :"How soon is housing needed?" },
                  { id : "q3", type :"radio", text :"Is this a domestic violence situation?", options : ["Yes", "No"] },
                  { id : "q4", type :"radio", text :"How soon is housing needed?", options : ["One", "More than One"] },
                  { id : "q5", type :"text", text :"How many individuals need shelter. Please provide age and gender."},
                  { id : "q6", type :"radio", text :"Do you have transportation?", options : ["Yes", "No"]},
                  { id : "q7", type :"text", text :"What is your primary search area?" },
                  { id : "q8", type :"text", text :"Please include any additional, relevant information." },
                ]
              }
            ]
      },
      {
        id: "cat7",
        type: "Category",
        name: "Legal",
        items :[
              {
                id: "req43",
                type: "request",
                name: "Lawyers",
                questions :[
                  { id : "q1", type :"text", text :"What is your legal concern?" },
                  { id : "q2", type :"text", text :"Do you have a court date. If YES, when is it?" },
                  { id : "q3", type :"text", text :"What county is the legal matter taking place in?" },
                  { id : "q4", type :"text", text :"What is your primary search area?" },
                  { id : "q5", type :"text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                id :"req44",
                type : "request",
                name: "Mediators", 
                questions :[
                  { id : "q1", type :"text", text :"What is your legal concern?" },
                  { id : "q2", type :"text", text :"Do you have a court date. If YES, when is it?" },
                  { id : "q3", type :"text", text :"What county is the legal matter taking place in?" },
                  { id : "q4", type :"text", text :"What is your primary search area?" },
                  { id : "q5", type :"text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                id :"req45",
                type : "request",
                name: "Legal Aid", 
                questions :[
                  { id : "q1", type :"text", text :"What is your legal concern?" },
                  { id : "q2", type :"text", text :"Do you have a court date. If YES, when is it?" },
                  { id : "q3", type :"text", text :"What county is the legal matter taking place in?" },
                  { id : "q4", type :"text", text :"What is your primary search area?" },
                  { id : "q5", type :"text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                id :"req46",
                type : "request",
                name: "Legal - Other", 
                questions :[
                  { id : "q1", type :"text", text :"Please specify type." },
                  { id : "q2", type :"text", text :"What is your legal concern?" },
                  { id : "q3", type :"text", text :"Do you have a court date. If YES, when is it?" },
                  { id : "q4", type :"text", text :"What county is the legal matter taking place in?" },
                  { id : "q5", type :"text", text :"What is your primary search area?" },
                  { id : "q6", type :"text", text :"Please include any additional, relevant information." },
                ]
              }
        ]
      },
      {
        id : "cat8",
        type : "Category",
        name :"Parenting and Education",
        items :[
              {
                id: "req47",
                type: "request",
                name: "Adoption",
                questions:  [
                  { id: "q1", type: "text", text: "Please explain where you are in the adoption process?"},
                  { id: "q2", type: "number", text: "What is your age?" },
                  { id: "q3", type: "radio", text: "What is your marital status?",options :["Married", "Single", "Parented"] },
                  { id: "q4", type: "radio", text: "Do you have any other children?", options :["Yes", "No"] },
                  { id: "q5", type: "radio", text: "What type of adoption are you seeking?", options : ["Domestic", "International", "Private", "Open", "Other"] },
                  { id: "q6", type: "text", text: "Some adoption agencies may have religious restrictions/requirements. Are you interested in adoption agencies that have a religious requirement?" },
                  { id: "q7", type: "text", text: "What age child do you wish to adopt?" },
                  { id: "q8", type: "text", text: "Are you open to adopting a child with special needs?" },
                ]
              },
              {
                id: "req48",
                type: "request",
                name: "Education",
                questions :[
                  { id: "q1", type: "text", text: "What are your needs in regards to education?" },
                  { id: "q2", type: "text", text: "Who is the student?" },
                  { id: "q3", type: "text", text: "What resources are you seeking?" },
                ]
              },
              {
                id: "req49",
                type: "request",
                name: "Tutor",
                questions :[
                  { id: "q1", type: "radio", text: "What type of tutor would you like?", options :["In-home", "Online", "Learning Center"] },
                  { id: "q2", type: "text", text: "What subjects require tutoring?" },
                  { id: "q3", type: "text", text: "Have you contacted the school to see if tutoring is available? If you would like us to check, what is the name of the school?" },
                  { id: "q4", type: "text", text: "Does the student have any learning disabilities or special needs? If YES, goes to Please specify." },
                  { id: "q5", type: "text", text: "What is your primary search area?" },
                  { id: "q6", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req50",
                type: "request",
                name: "Colleges",
                questions :[
                  { id: "q1", type: "text", text: "What is your student’s major/program of study?" },
                  { id: "q2", type: "text", text: "What type of degree is the student pursuing?" },
                  { id: "q3", type: "text", text: "What type of institution would the student like to attend?" },
                  { id: "q4", type: "text", text: "Will the student like to attend full-time or part-time?" },
                  { id: "q5", type: "text", text: "What is your primary search area?" },
                  { id: "q6", type: "text", text: "Please include any additional, relevant information." },
                ]
              },
              {
                id: "req51",
                type: "request",
                name: "Scholarships/Loans/Financial Aid",
                questions :[
                  { id: "q1", type: "text", text: "What is your student’s major/program of study?" },
                  { id: "q2", type: "text", text: "What year will the student be entering?" },
                  { id: "q3", type: "radio", text: "Is the student a minority?", options :["Yes", "No"] },
                  { id: "q4", type: "radio", text: "Is there anyone in the student’s immediate family who has served in the military?", options :["Yes", "No"] },
                  { id: "q5", type: "radio", text: "Are you interested in student loan information?", options :["Yes", "No"] },
                  { id: "q6", type: "radio", text: "Are you interested in College Investment/Savings Plan Information?", options :["Yes", "No"] },
                  { id: "q7", type: "text", text :"What is your primary search area"},
                  { id: "q8", type: "text", text :"Please include any additional, relevant information."},
                ]
              },
              {
                id: "req52",
                type: "request",
                name: "Scholarships/Loans/Financial Aid",
                questions : [
                  { id: "q1", type: "radio", text: "What type of school are you interested in for your student?", options :[
                    "Public", "Private", "Charter", "Boarding", "Specialized Day School", "Therapeutic", "Special Needs", " Virtual or Homeschool", "Other"
                  ] },
                  { id: "q2", type: "text", text :"What type of public school information do you require?"},
                  { id: "q3", type: "text", text :"Does the student have any special needs that the school would need to accommodate. If YES, goes to Please explain."},
                  { id: "q4", type: "text", text :"When do your plan on enrolling your student?"},
                  { id: "q5", type: "text", text :"What grade will the student be entering?"},
                  { id: "q6", type: "text", text :"Are there any schools that you would like us to contact?"},
                  { id: "q7", type: "text", text :"Do you have any specific curriculum requests?"},
                  { id: "q8", type: "text", text :"What is your primary search area?"},
                  { id: "q9", type: "text", text :"Please include any additional, relevant information."},
                ]
              },
              {
                id : "req53",
                type : "request",
                name :"Local School District Information",
                questions : [
                  { id: "q1", type: "text", text :"Does the student have any special needs that the school would need to accommodate. If YES, goes to Please explain."},
                  { id: "q2", type: "text", text :"When do your plan on enrolling your student?" },
                  { id: "q3", type: "text", text :"What grade will the student be entering?" },
                  { id: "q4", type: "text", text :"What is your primary search area?" },
                  { id: "q5", type: "text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                id : "req54",
                type : "request",
                name :"Continuing Education/Professional Education",
                questions : [
                  { id: "q1", type: "text", text :"What is the requested subject matter?" },
                  { id: "q2", type: "text", text :"What is the purpose of this course?" },
                  { id: "q3", type: "radio", text :"Are credits or proof of completion required?", options :["Yes", "no"] },
                  { id: "q4", type: "text", text :"What is your time frame for beginning/completing this course?" },
                  { id: "q5", type: "text", text :"Where would you like us to search for the course/webinar/program?" },
                  { id: "q6", type: "text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                
                id : "req55",
                type : "request",
                name :"Education Coach",
                questions : [
                  { id: "q1", type: "text", text :"What is the specific need/focus of the requested coach?" },
                  { id: "q2", type: "text", text :"ADD/ADHD, Autism, Other Learning Disabilities or Special Needs?" },
                  { id: "q3", type: "radio", text :"Are you ok with sessions provided by phone or via online?", options :["Yes", "No"] },
                  { id: "q4", type: "text", text :"What is your primary search area?" },
                  { id: "q5", type: "text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                id : "req56",
                type : "request",
                name :"Pregnancy",
                questions : [
                  { id: "q1", type: "text", text :"What are your needs in regards to pregnancy?" },
                  { id: "q2", type: "text", text :"What parenting resources are you seeking?" },
                  { id: "q3", type: "text", text :"If classes, what type of pregnancy classes are you interested in?" },
                  { id: "q4", type: "text", text :"What is child's due date?" },
                  { id: "q5", type: "text", text :"What is the name of the hospital you plan to give birth?" },
                  { id: "q6", type: "text", text :"Would online courses/programs be helpful?" },
                  { id: "q7", type: "text", text :"What is your primary search area?" },
                  { id: "q8", type: "text", text :"Please include any additional, relevant information." },
                ]
              },
              {
                id : "req57",
                type : "request",
                name :"Miscellaneous Parenting",
                questions : [
                  { id: "q1", type: "radio", text :"Are you in need of parenting classes?", options :["Yes", "No"] },
                  { id: "q2", type: "text", text :"What type of parenting classes are you interested in? Are they court ordered?" },
                  { id: "q3", type: "radio", text :"Are you in need of a lactation consultant?", options :["Yes", "No"] },
                  { id: "q4", type: "radio", text :"Would you like information on parenting educational support resources?",options :["Yes", "No"] },
                  { id: "q5", type: "text", text :"If so, what topic?" },
                  { id: "q6", type: "text", text :"Would online courses/programs be helpful?" },
                  { id: "q7", type: "text", text :"What is your primary search area?" },
                  { id: "q8", type: "text", text :"Please include any additional, relevant information." },
                ]
              }
            ]
      }
]

const data: WorkLifeData = workLifeData

export function getRequestObjectsFromCategoryId(categoryId: string): { categoryName: string; items: (Request | Category)[] } | null {
  function findItem(items: (Request | Category)[], categoryName?: string): { categoryName: string; items: (Request | Category)[] } | null {
    for (const item of items) {
      if (item.type === "Category") {
        if (item.id === categoryId) {
          return { categoryName: item.name, items: item.items };
        }
        const found = findItem(item.items, item.name);
        if (found) {
          return found;
        }
      } else if (item.type === "request") {
        if (item.id === categoryId) {
          return { categoryName: categoryName || '', items: [item] };
        }
      }
    }
    return null;
  }

  for (const category of data) {
    if (category.type === "Category") {
      if (category.id === categoryId) {
        return { categoryName: category.name, items: category.items };
      }
      const found = findItem(category.items, category.name);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

export function getQuestionsByRequestId(requestId: string): { requestName: string; questions: Question[] } | null {
  function findQuestions(items: (Request | Category)[]): { requestName: string; questions: Question[] } | null {
    for (const item of items) {
      if (item.type === "request" && item.id === requestId) {
        return { requestName: item.name, questions: item.questions };
      } else if (item.type === "Category") {
        const result = findQuestions(item.items);
        if (result) return result;
      }
    }
    return null;
  }

  for (const category of data) {
    if (category.type === "Category") {
      const result = findQuestions(category.items);
      if (result) return result;
    }
  }
  return null;
}