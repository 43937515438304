import React from "react";
import { Navigate } from "react-router-dom";
import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import { storageKeys } from "../../../utils/constants";
import Homepage from "./Homepage";
import { clearLocalStorageExceptOID } from "../../../utils/clearStorageData";

const Home: React.FC = () => {
  const token: string | null = localStorage.getItem(storageKeys.TOKEN);
  const userType: string | null = localStorage.getItem(storageKeys.USER_TYPE);
  if (token) {
    if (userType) {
      if (userType === "customer") {
        return <Navigate to="/account-info" />;
      }
      else return <Navigate to="/dashboard" />;
    } else {
      setTimeout(() => {
        clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
      }, 100)
      return <Navigate to="/login" />
    }
  } else {
    return (
      <FixedHeaderUserLayout hideMyAccButton={true}>
        <Homepage />
      </FixedHeaderUserLayout>
    );
  }
};

export default Home;
